import { Accordion } from '../brite-viewer/components/accordion';
import { Benefits } from '../brite-viewer/components/benefits/benefits';
import { Button } from '../brite-viewer/components/button';
import { Document } from '../brite-viewer/components/document';
import { Image } from '../brite-viewer/components/image';
import { Space } from '../brite-viewer/components/space';
import { Text } from '../brite-viewer/components/text';
import { TextV2 } from '../brite-viewer/components/text-v2';
import { Video } from '../brite-viewer/components/video';
import { Website } from '../brite-viewer/components/website';
import { getAttributes } from '../brite-viewer/utils';
import { useViewer, Viewer } from './ViewerProvider';

const blockMap = {
  text: Text,
  textV2: TextV2,
  space: Space,
  line: Space,
  image: Image,
  video: Video,
  website: Website,
  button: Button,
  benefits: Benefits,
  pricing: Benefits,
  network: Benefits,
  document: Document,
  accordion: Accordion,
};

export const Block = ({ id, type }) => {
  const viewer = useViewer();
  const parentBlock = Viewer.getBlock(viewer, id);
  const childCount = parentBlock?.children?.length;

  const childBlock = Viewer.getBlock(viewer, id);
  const BlockComponent = blockMap?.[type];

  const { properties, ...rest } = childBlock;
  const data = {
    ...rest,
    ...properties,
    component: rest?.type,
  };

  const containerAttributes = getAttributes({
    column: data,
    length: childCount,
    type: 'container',
  });

  const style = {
    ...containerAttributes.style,
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
  };

  const props = { item: data, totalColumns: childCount, containerAttributes };

  return (
    <div className="arrange-block">
      <div {...containerAttributes} style={style}>
        <BlockComponent {...props} />
      </div>
    </div>
  );
};
