import React from 'react';
import { Benefits } from './components/benefits/benefits';
import { Button } from './components/button';
import { Document } from './components/document';
import { Image } from './components/image';
import { Space } from './components/space';
import { Text } from './components/text';
import { TextV2 } from './components/text-v2';
import { Video } from './components/video';
import { Website } from './components/website';
import { getAttributes } from './utils';
import { Accordion } from './components/accordion';

export const Component = (props) => {
  const { item, totalColumns } = props;

  const containerAttributes = getAttributes({
    column: item,
    length: totalColumns,
    type: 'container',
  });

  const style = {
    ...containerAttributes.style,
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
  };

  const data = { ...props, containerAttributes };

  return (
    <div {...containerAttributes} style={style}>
      {item.component === 'text' && <Text {...data} />}
      {item.component === 'textV2' && <TextV2 {...data} />}
      {item.component === 'space' && <Space {...data} />}
      {item.component === 'line' && <Space {...data} />}
      {item.component === 'image' && <Image {...data} />}
      {item.component === 'video' && <Video {...data} />}
      {item.component === 'website' && <Website {...data} />}
      {item.component === 'button' && <Button {...data} />}
      {item.component === 'benefits' && <Benefits {...data} />}
      {item.component === 'pricing' && <Benefits {...data} />}
      {item.component === 'network' && <Benefits {...data} />}
      {item.component === 'document' && <Document {...data} />}
      {item.component === 'accordion' && <Accordion {...data} />}
      {item.component === 'pageBreak' && <div style={{ breakAfter: 'page' }} />}
    </div>
  );
};
