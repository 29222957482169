import { getAttributes } from '../brite-viewer/utils';
import { Accordion } from './components/Accordion';
import { Benefits } from './components/Benefits';
import { Button } from './components/Button';
import { Document } from './components/Document';
import { Image } from './components/Image';
import { Space } from './components/Space';
import { Text } from './components/Text';
import { Video } from './components/Video';
import { Website } from './components/Website';

export const PDFComponent = (props) => {
  const { item, totalColumns } = props;

  // Quick return if the component is hidden on pdf render.
  if (item?.mediaSetting?.pdf?.hidden) {
    return null;
  }

  const containerAttributes = getAttributes({
    column: item,
    length: totalColumns,
    type: 'container',
  });

  const containerStyle = {
    ...containerAttributes.style,
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
  };

  const data = { ...props, containerAttributes, containerStyle };

  switch (item.component) {
    case 'accordion':
      return <Accordion {...data} />;
    case 'benefits':
    case 'pricing':
    case 'network':
      return <Benefits {...data} />;
    case 'button':
      return <Button {...data} />;
    case 'document':
      return <Document {...data} />;
    case 'image':
      return <Image {...data} />;
    case 'space':
    case 'line':
      return <Space {...data} />;
    case 'text':
    case 'textV2':
      return <Text {...data} />;
    case 'video':
      return <Video {...data} />;
    case 'website':
      return <Website {...data} />;
    case 'pageBreak':
      return <div style={{ breakAfter: 'page' }} />;
    default:
      return null;
  }
};
