import { useState, useEffect, useCallback } from 'react';
import { CroppedImage } from '../../brite-viewer/components/cropped-image';
import { getAttributes } from '../../brite-viewer/utils';

export const Image = ({ item, totalColumns, containerAttributes, containerStyle }) => {
  const componentAttributes = getAttributes({
    column: item,
    length: totalColumns,
    type: 'component',
  });

  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isImageError, setIsImageError] = useState(false);

  const onLoad = () => {
    setIsImageLoaded(true);
  };

  const onError = () => {
    setIsImageLoaded(true);
    setIsImageError(true);
  };

  // If the URL is invalid, render nothing.
  if (isImageLoaded && isImageError) {
    return null;
  }

  return (
    <div style={containerStyle}>
      {item?.crop ? (
        <CroppedImage
          style={componentAttributes?.style}
          width={containerAttributes?.style?.width}
          crop={item?.crop}
          imageProps={{
            src: componentAttributes?.src,
            alt: componentAttributes?.alt,
          }}
          onError={onError}
          onLoad={onLoad}
        />
      ) : (
        <img alt="" {...componentAttributes} onError={onError} onLoad={onLoad} />
      )}
    </div>
  );
};
